import { createStore } from 'vuex'
import { auth } from './auth.module'
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
  },
})
