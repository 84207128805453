<template>
  <div>
    <div class="wrapper d-flex flex-column min-vh-100 bg-light">
      <AppHeader />
      <div class="body flex-grow-1 px-3">
        <CContainer xxl>
          <router-view />
        </CContainer>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
<script>
import { CContainer } from '@coreui/vue'
import AppFooter from '@/components/AppFooter.vue'
import AppHeader from '@/components/AppHeader.vue'

export default {
  name: 'DefaultLayout',
  components: {
    AppFooter,
    AppHeader,
    CContainer,
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user
    },
  },
  watch: {
    currentUser: {
      handler(newValue) {
        if (!newValue) {
          this.$router.push('/login')
        }
      },
      immediate: true,
    },
  },
}
</script>
