import axios from 'axios'

const BASE_API_URL = process.env.VUE_APP_ADMIN_BASE_API
const ACCOUNT_API = BASE_API_URL + 'account/'
class AuthService {
  login(user) {
    return axios
      .post(ACCOUNT_API + 'login', {
        userName: user.userName,
        password: user.password,
      })
      .then((response) => {
        if (response.data.token) {
          let account = response.data
          account.userName = user.userName
          localStorage.setItem('user', JSON.stringify(account))
        }

        return response.data
      })
  }

  logout() {
    localStorage.removeItem('user')
  }
}

export default new AuthService()
